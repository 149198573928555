import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <div className="container lg:px-0 py-20">
                    <Title>Preview del llibre</Title>
                    <Text className="text-justify">
                        A continuació us adjuntem una vista prèvia de la guia en format PDF.
                    </Text>

                    <div className="rounded p-5 border shadow-md mt-5">
                        <embed
                            src={require("@static/documents/products/611/preview.pdf")}
                            type="application/pdf"
                            className=""
                            width="100%"
                            height="750px"
                        ></embed>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate item={item} title="">
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
